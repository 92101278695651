import { Group, Text, Stack, ActionIcon } from '@mantine/core';

import { IconArrowRight, IconExternalLink } from '@tabler/icons-react';

import { BrandedCard } from '../../../../shared/components/UI/BrandedCard/BrandedCard';
import { RenewalTag } from '../../../../shared/components/UI/RenewalTag/RenewalTag';
import { TenderDataDisplay } from '../../../../shared/components/UI/TenderDataDisplay/TenderDataDisplay';
import TenderStatusBadge from '../../../../shared/components/UI/TenderStatusBadge/TenderStatusBadge';
import { Tooltip } from '../../../../shared/components/UI/Tooltip/Tooltip';

import type { Location } from '../../../../shared/entities/Location';
import { formatCurrency } from '../../../../shared/utils/curencies';
import { formatDateAsText } from '../../../../shared/utils/dates';
import { goToGmap } from '../../../../shared/utils/navigation';
import { useNoticeContext } from '../../contexts/Notice.provider';

export default function DataCard() {
  const { tender } = useNoticeContext();

  const publicationDate = formatDateAsText(tender.publicationDate, false, false);
  const responseDeadline = tender.responseDeadline ? formatDateAsText(tender.responseDeadline, false, false) : '-';

  return (
    <BrandedCard title="Informations clés" variant="filled" w="100%">
      <Stack spacing="04">
        <Group noWrap spacing="03">
          <TenderDataDisplay
            variant="outlined"
            statTitle={
              <Group noWrap spacing="02">
                <Text variant="xs" fw="500" c="gray.6">
                  Dates
                </Text>
                <TenderStatusBadge status={tender?.status} size="xs" />
              </Group>
            }
            statValue={
              <Group noWrap spacing={4} c="gray.5">
                <Text variant="md" fw={400} c="gray.9">
                  {publicationDate}
                </Text>
                <IconArrowRight size={16} />
                <Text variant="md" fw={400} c="gray.9">
                  {responseDeadline}
                </Text>
              </Group>
            }
          />
          <TenderDataDisplay
            variant="outlined"
            statTitle="Montant"
            statValue={formatCurrency(tender?.estimatedValueInEur) || '-'}
            isLmmEnriched={tender?.isEstimatedValueInEurLLMEnriched}
          />
          <TenderDataDisplay
            variant="outlined"
            statTitle="Durée"
            statValue={tender?.durationInMonth ? `${tender.durationInMonth} mois` : '-'}
            isLmmEnriched={tender?.isDurationInMonthLLMEnriched}
          />
          <TenderDataDisplay
            variant="outlined"
            statTitle="Lieu d'éxcécution"
            statValue={
              tender?.executionLocation?.city ? (
                <LocationComponent location={tender.executionLocation} />
              ) : (
                '-'
              )
            }
          />
          <TenderDataDisplay
            variant="outlined"
            statTitle="Nombre de lots"
            statValue={
              tender?.lots && tender.lots.length > 0
                ? `${tender.lots.length} ${tender.lots.length > 1 ? 'lots' : 'lot'}`
                : 'Unique'
            }
          />
        </Group>
        {tender.isRenewal && <RenewalTag />}
      </Stack>
    </BrandedCard>
  );
}

type LocationComponentProps = {
  location: Location;
};

const LocationComponent = ({ location }: LocationComponentProps) => {
  return (
    <Group noWrap spacing="01">
      <Text variant="md" fw="400" c="gray.9">
        {location.city}
      </Text>
      <Tooltip content={'Ouvrir Google Maps'}>
        <ActionIcon size="xs" color="primary" onClick={() => goToGmap(location)}>
          <IconExternalLink />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};
