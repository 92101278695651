import { useState } from 'react';

import { Stack, Title, Text, Group, Box, Button } from '@mantine/core';

import { IconX } from '@tabler/icons-react';

import type {
  requirementEvaluation,
  JudgementCriterion,
} from '../../../../shared/entities/BidResponse';
import { createCriteriaTree } from '../../utils/createCriteriaTree';
import { FilterCriteriaButton } from './FilterCriteriaButton';
import { FilterMissingElementsButton } from './FilterMissingElementsButton';
import { type RequirementEvaluationFilter } from './SimulationTab';

export type FilterButtonProps = {
  onClick?: (callback: RequirementEvaluationFilter['callback'] | null, type: string) => void;
  selected?: boolean;
  requirementEvaluation: requirementEvaluation[];
};

type EvalCardProps = {
  filter: boolean;
  missingElements: number;
  setFilter: (filter: RequirementEvaluationFilter) => void;
  removeFilter: (type: string) => void;
  clearFilter: () => void;
  judgementCriteria: JudgementCriterion[];
  requirementEvaluations: requirementEvaluation[];
};

export const GridFilters = ({
  filter,
  setFilter,
  removeFilter,
  clearFilter,
  judgementCriteria,
  requirementEvaluations,
}: EvalCardProps) => {
  const criteriaTree = createCriteriaTree(judgementCriteria);

  console.log('🚀 ~ criteriaTree:', criteriaTree);

  const [activeFilters, setActiveFilters] = useState<string[] | null>(null);

  const handleNewFilter = (
    callback: RequirementEvaluationFilter['callback'] | null,
    type: string,
  ) => {
    if (callback === null) {
      removeFilter(type);
      setActiveFilters(prev => prev?.filter(f => f !== type) || null);
    } else {
      setFilter({ type, callback });
      setActiveFilters(prev => (prev ? [...prev, type] : [type]));
    }
  };
  const handleClearFilter = () => {
    clearFilter();
    setActiveFilters(null);
  };

  return (
    <Group
      align="flex-start"
      noWrap
      spacing="06"
      px="06"
      py="05"
      sx={{
        borderRadius: `12px 12px 0px 0px`,
        background: `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0.00) 100%)`,
      }}
    >
      <Stack spacing="04" align="flex-start">
        <Group spacing="03" noWrap>
          <Title order={5} c="dark.9">
            Évaluation
          </Title>
          <Box h="16px" sx={theme => ({ borderLeft: `1px solid ${theme.colors.gray[2]}` })} />
          <Text variant="sm" fw={400} c="dark.9">
            Filtrer par
          </Text>
        </Group>
        <Group spacing="03">
          {criteriaTree.map(criteria => (
            <FilterCriteriaButton
              key={criteria.id}
              selected={activeFilters?.includes(criteria.title)}
              onClick={handleNewFilter}
              criteriaWithChildren={criteria}
              requirementEvaluation={requirementEvaluations}
            />
          ))}
          <FilterMissingElementsButton
            selected={activeFilters?.includes('missing element')}
            onClick={handleNewFilter}
            requirementEvaluation={requirementEvaluations}
          />
          {filter && <ClearFilterButton onClick={handleClearFilter} />}
        </Group>
      </Stack>
    </Group>
  );
};

const ClearFilterButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      variant="subtle"
      c="gray.8"
      size="sm"
      onClick={onClick}
      leftIcon={<IconX size={16} stroke={1.5} />}
    >
      Réinitialiser
    </Button>
  );
};
