import { Stack, Group, Text } from '@mantine/core';

import { BrandedCard } from '../../../../shared/components/UI/BrandedCard/BrandedCard';

import type { JudgementCriterion } from '../../../../shared/entities/BidResponse';
import { CriteriaCard } from './CriteriaCard';

type SummaryCardProps = {
  total: number;
  missing: number;
  criteria: JudgementCriterion[];
};

export const SummaryCard = (props: SummaryCardProps) => {
  return (
    <BrandedCard variant="filled" title="Résumé de la simulation">
      <Stack spacing="05">
        <StatsRow {...props} />
      </Stack>
    </BrandedCard>
  );
};

const StatsRow = ({ total, missing, criteria }: SummaryCardProps) => {
  return (
    <Group noWrap spacing="05" align="flax-start">
      <StatCard title="Exigences détectées" stats={total} />
      <StatCard title="Éléments de réponse manquants" stats={missing} />
      <CriteriaCard criteria={criteria} />
    </Group>
  );
};

type StatCardProps = {
  title: string;
  stats: number;
};

const StatCard = ({ title, stats }: StatCardProps) => {
  return (
    <Stack
      spacing="02"
      py="04"
      px="05"
      bg="white"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
      })}
    >
      <Text variant="sm" fw={500} c="gray.5">
        {title}
      </Text>
      <Text fw="600" c="gray.9" fz="40px">
        {stats}
      </Text>
    </Stack>
  );
};
