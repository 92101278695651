import { Suspense, useEffect } from 'react';

import { AppShell } from '@mantine/core';
import { modals } from '@mantine/modals';

import * as Sentry from '@sentry/react';
import * as reactRouterDom from 'react-router-dom';
import { IconArrowsShuffle, IconBallpen, IconLayoutKanban } from '@tabler/icons-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import posthog from 'posthog-js';
import { Outlet, useLocation } from 'react-router-dom';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { Error } from '../shared/components/UI/Error/Error.tsx';
import { Loader } from '../shared/components/UI/Loader/Loader.tsx';
import { Navbar } from '../shared/components/UI/Navbar/Navbar.tsx';

import * as superTokens from '../shared/infra/supertokens.ts';
import FeatureFlagsAndActiveUserProvider, {
  useActiveUser,
} from '../shared/contexts/FeatureFlagsAndActiveUser.provider.tsx';
import { ThemeProvider } from '../shared/contexts/MantineTheme.provider.tsx';
import ModalsProvider from '../shared/contexts/Modals.provider.tsx';
import { UrlHashProvider } from '../shared/contexts/UrlHash.provider.tsx';
import { FeatureFlag } from '../shared/entities/FeatureFlags.ts';
import '../shared/infra/posthog.ts';
import { queryClient } from '../shared/infra/queryClient.ts';
import { Router } from './Router.tsx';

const { BrowserRouter } = reactRouterDom;

superTokens.init();

export default function App() {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <SuperTokensWrapper>
          <FeatureFlagsAndActiveUserProvider>
            <ModalsProvider>
              <BrowserRouter>
                <UrlHashProvider>
                  <Router />
                </UrlHashProvider>
              </BrowserRouter>
            </ModalsProvider>
          </FeatureFlagsAndActiveUserProvider>
        </SuperTokensWrapper>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export function AppWithLogin() {
  const session = useSessionContext();
  const { activeUser, ignoreInReporting } = useActiveUser();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const onboarding = searchParams.get('onboarding');

  useEffect(() => {
    if (!session.loading && session.doesSessionExist) {
      if (import.meta.env.PROD) {
        const supertokensUserId = session.userId;
        const email = session.accessTokenPayload.email;
        const firstName = session.accessTokenPayload.firstName;
        const lastName = session.accessTokenPayload.lastName;
        const companyUniqueName = session.accessTokenPayload.companyUniqueName;
        const companyDisplayName = session.accessTokenPayload.companyDisplayName;

        posthog.identify(supertokensUserId, { email, ignoreInReporting, firstName, lastName });
        posthog.group('company', companyUniqueName, {
          companyUniqueName,
          companyDisplayName,
        });
      }
    }
  }, [ignoreInReporting, session]);

  useEffect(() => {
    if (onboarding === 'true') {
      openOnboardingModal();
    }
  }, [onboarding]);

  const openOnboardingModal = () => {
    modals.openContextModal({
      fullScreen: true,
      withCloseButton: true,
      modal: 'onboarding',
      transitionProps: { transition: 'slide-up', duration: 300, timingFunction: 'linear' },
      innerProps: { step: 0 },
    });
  };

  return (
    <AppShell
      padding={0}
      header={
        <Navbar
          links={[
            {
              link: `/flux`,
              label: "Flux d'opportunités",
              icon: <IconArrowsShuffle size={16} />,
            },
            {
              link: '/pipeline',
              label: 'Pipeline',
              icon: <IconLayoutKanban size={16} />,
            },
            {
              link: '/reponse',
              label: 'Aide à la réponse',
              icon: <IconBallpen size={16} />,
              featureFlag: FeatureFlag.BUYER_SIMULATION,
            },
          ]}
          user={activeUser}
        />
      }
    >
      <Sentry.ErrorBoundary fallback={<Error />}>
        <Suspense fallback={<Loader title="Chargement" />}>
          <Outlet />
        </Suspense>
      </Sentry.ErrorBoundary>
    </AppShell>
  );
}
