import { Group, Stack, Title, Text, Box, Skeleton, Button } from '@mantine/core';

import { IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';

import { getAssessmentsRecap } from '../../../shared/api/magellan/instantAnalysis/answers';
import { AssessmentStatus } from '../../../shared/entities/AssessmentStatus';
import { AssessmentButton } from './AssessmentButton';
import { AssessmentCircularGraph } from './AssessmentCircularGraph';

type AssessmentBannerProps = {
  tenderId: number;
  filter: AssessmentStatus | null;
  setFilter: (filter: AssessmentStatus | null) => void;
};

export function AssessmentBanner({ tenderId, filter, setFilter }: AssessmentBannerProps) {
  const { isPending, isError, data, error } = useQuery({
    queryKey: [getAssessmentsRecap.name, tenderId],
    queryFn: () => getAssessmentsRecap(tenderId),
  });

  const handleFilterChange = (assessment: AssessmentStatus | null) => {
    setFilter(assessment);
  };

  if (isPending) {
    return <LoaderComponent />;
  }

  if (isError) {
    throw error;
  }

  return (
    <Group
      align="flex-start"
      noWrap
      spacing="06"
      px="06"
      py="05"
      sx={{
        borderRadius: `12px 12px 0px 0px`,
        background: `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0.00) 100%)`,
      }}
    >
      <AssessmentCircularGraph assessmentRecap={data.assessmentRecap} />
      <Stack spacing="04" align="flex-start">
        <Group spacing="03" noWrap>
          <Title order={5} c="dark.9">
            Avis global
          </Title>
          <Box h="16px" sx={theme => ({ borderLeft: `1px solid ${theme.colors.gray[2]}` })} />
          <Text variant="sm" fw={400} c="dark.9">
            Filtrer par
          </Text>
        </Group>
        <Group spacing="03">
          {data.assessmentRecap.unAssessed > 0 && (
            <AssessmentButton
              assessment={AssessmentStatus.UNASSESSED}
              count={data.assessmentRecap.unAssessed}
              selected={filter === AssessmentStatus.UNASSESSED}
              onClick={handleFilterChange}
              withFilledBadge
            />
          )}
          <AssessmentButton
            assessment={AssessmentStatus.POSITIVE_POINT}
            count={data.assessmentRecap.positivePoint}
            selected={filter === AssessmentStatus.POSITIVE_POINT}
            onClick={handleFilterChange}
            withFilledBadge
          />
          <AssessmentButton
            assessment={AssessmentStatus.NEUTRAL_POINT}
            count={data.assessmentRecap.neutralPoint}
            selected={filter === AssessmentStatus.NEUTRAL_POINT}
            onClick={handleFilterChange}
            withFilledBadge
          />
          <AssessmentButton
            assessment={AssessmentStatus.ATTENTION_POINT}
            count={data.assessmentRecap.attentionPoint}
            selected={filter === AssessmentStatus.ATTENTION_POINT}
            onClick={handleFilterChange}
            withFilledBadge
          />
          <AssessmentButton
            assessment={AssessmentStatus.BLOCKING_POINT}
            count={data.assessmentRecap.blockingPoint}
            selected={filter === AssessmentStatus.BLOCKING_POINT}
            onClick={handleFilterChange}
            withFilledBadge
          />
          {filter && <ClearFilterButton onClick={() => setFilter(null)} />}
        </Group>
      </Stack>
    </Group>
  );
}

const LoaderComponent = () => {
  return (
    <Group
      align="flex-start"
      noWrap
      spacing={32}
      px={32}
      py={24}
      sx={{
        borderRadius: `12px 12px 0px 0px`,
        background: `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0.00) 100%)`,
      }}
    >
      <Skeleton height={82} circle sx={{ background: 'red.5' }} />
      <Stack spacing={16} align="flex-start">
        <Group spacing={12} noWrap>
          <Title order={5} c="dark.9">
            Avis global
          </Title>
          <Box h="16px" sx={theme => ({ borderLeft: `1px solid ${theme.colors.gray[2]}` })} />
          <Text variant="sm" fw={400} c="dark.9">
            Filtrer par
          </Text>
        </Group>
        <Group spacing={12}>
          <Skeleton height={32} width={120} />
          <Skeleton height={32} width={100} />
          <Skeleton height={32} width={130} />
          <Skeleton height={32} width={120} />
        </Group>
      </Stack>
    </Group>
  );
};

const ClearFilterButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      variant="subtle"
      c="gray.8"
      size="sm"
      onClick={onClick}
      leftIcon={<IconX size={16} stroke={1.5} />}
    >
      Réinitialiser
    </Button>
  );
};
